import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styles from './style.module.css';
import Button from '../../ui/button';
import Text from '../../ui/text';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Icon from '../../ui/icon';
import DesignListRow from '../../components/design-list-row';
import Status from '../../ui/status';
import DesignQuickViewModal from '../../components/design-quick-view-modal';
import { Design } from '../../types';
import { debounce } from 'lodash';
import { DesignDataProvider } from '../../common/context/designData';
import Search from '../../ui/search';

interface SearchAppProps {}

const SearchApp = ({}: SearchAppProps): ReactElement => {
  const { handleRelocate } = UseAuth();
  const [designs, setDesigns] = useState<Design[]>([]);
  const [designCount, setDesignCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState('show_all');
  const [filters, setFilters] = useState<Record<string, string | boolean>>({});
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [triggerShowQuickView, setTriggerShowQuickView] = useState<number>(0);
  const [shownDesignId, setShownDesignId] = useState<string>('');

  const fetchDesigns = async (): Promise<void> => {
    setLoading(true);
    setError(false);
    setErrorMessage(undefined);
    const queryParams = new URLSearchParams();

    if (filters.search) {
      queryParams.append('search', filters.search as string);
    }
    if (filters.status) {
      queryParams.append('status', filters.status as string);
    }
    if (filters['created_by_me']) {
      queryParams.append('created_by_me', filters['created_by_me'].toString());
    }

    const response = await fetch(
      `/api/order/orders/?${queryParams.toString()}`
    );
    if (!response.ok) {
      // Handle this
      setError(true);
      return;
    }
    const data = await response.json();
    setDesigns(data.results);
    setDesignCount(data.count || 0);
    setLoading(false);
  };

  const setFiltersFromPartial = (
    partialFilers: Record<string, string | boolean>
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...partialFilers,
    }));
  };

  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFiltersFromPartial(newFilters);
    }, 500),
    []
  );

  useEffect(() => {
    if (!searchTerm && !('search' in filters)) {
      // on initial load or on deletion, immediately set filters and fetch
      setFiltersFromPartial({ search: searchTerm });
    } else {
      debouncedSetFilters({ search: searchTerm });
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchDesigns();
  }, [filters]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value.toLowerCase());
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const goToDesign = (designId: string) => {
    handleRelocate(`design-view/${designId}`);
  };

  const openQuickView = (designId: string) => {
    setShownDesignId(designId);
    setTriggerShowQuickView((prev) => prev + 1);
  };

  const changeSelectedFilter = (filter: string) => () => {
    // For now filters are mutually exclusive, so simple to manage things
    // this way
    setSelectedFilter(filter);
    const newParams: Record<string, string | boolean> = {};
    switch (filter) {
      case 'show_all':
        break;
      case 'created_by_me':
        newParams[filter] = true;
        break;
      default:
        newParams['status'] = filter;
    }
    setFilters({
      ...newParams,
      search: searchTerm,
    });
  };

  useEffect(() => {
    if (!designs.length && !loading) {
      setError(true);
      setErrorMessage('No designs found');
    }
  }, [designs]);

  return (
    <DesignDataProvider
      designMode="view"
      usePropDesignId
      propDesignId={shownDesignId}
    >
      <div className={styles.page}>
        <div className={styles.top}>
          <Button
            onClick={() => handleRelocate('directory')}
            variant="secondary"
            size="lg"
          >
            <Icon name="backIcon" height="16px" width="10px"></Icon>Back
          </Button>
          <Search
            onClearInput={clearSearch}
            onChange={handleSearchInput}
            value={searchTerm}
            placeholder="Search reference number, name or created by"
          ></Search>
          <Icon name="traneLogo" width="109px" height="auto"></Icon>
        </div>
        <div className={styles.middle}>
          <div className={styles.designCount}>
            <Text fontWeight="300" size="ml" color="dark-grey">
              Designs
            </Text>
            <Text fontWeight="300" size="xs" color="dark-grey">
              {`(${designCount})`}
            </Text>
          </div>
          <div className={styles.filters}>
            <Button
              variant="filter"
              active={selectedFilter === 'show_all'}
              onClick={changeSelectedFilter('show_all')}
            >
              Show all
            </Button>
            <Button
              variant="filter"
              active={selectedFilter === 'created_by_me'}
              onClick={changeSelectedFilter('created_by_me')}
            >
              Created by me
            </Button>
            <Button
              variant="filter"
              active={selectedFilter === 'proposal'}
              onClick={changeSelectedFilter('proposal')}
            >
              Proposal
            </Button>
            <Button
              variant="filter"
              active={selectedFilter === 'quoted'}
              onClick={changeSelectedFilter('quoted')}
            >
              Quoted
            </Button>
            <Button
              variant="filter"
              active={selectedFilter === 'lost'}
              onClick={changeSelectedFilter('lost')}
            >
              Lost
            </Button>
            <Button
              variant="filter"
              active={selectedFilter === 'won'}
              onClick={changeSelectedFilter('won')}
            >
              Won
            </Button>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.designListTopRow}>
            <Text size="m" fontWeight="600" color="dark-grey">
              Reference nº
            </Text>
            <Text size="m" fontWeight="600" color="dark-grey">
              Design name
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Created by
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Date
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Status
            </Text>
          </div>
          {loading || error ? (
            <Status
              loading={loading}
              error={error}
              errorMessage={errorMessage}
              hideIcon={!!designs.length}
            ></Status>
          ) : (
            designs.map((design) => (
              <DesignListRow
                id={design.id}
                referenceNumber={design.reference_number}
                key={design.id}
                designName={design.design_name}
                createdBy={design.created_by}
                price={design.price}
                createdAt={design.created_at}
                email={design.email}
                status={design.status}
                onClick={openQuickView}
                goToDesign={goToDesign}
              ></DesignListRow>
            ))
          )}
          <DesignQuickViewModal
            triggerShow={triggerShowQuickView}
            designId={shownDesignId}
          ></DesignQuickViewModal>
        </div>
      </div>
    </DesignDataProvider>
  );
};
export default SearchApp;
