import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../types';

interface AuthContextInterface {
  handleLogout: (csrf?: string) => Promise<void>;
  handleLogin: (username: string, password: string) => Promise<Response>;
  handleRelocate: (url: string) => void;
  getUser: () => Promise<User>;
  isIpad: boolean;
  isAuthenticated: () => Promise<User | undefined>;
  getCsrf: () => Promise<string | null>;
  user: User | null;
}

const AuthContext = React.createContext<AuthContextInterface>(
  null as unknown as AuthContextInterface
);

export const UseAuth = () => {
  return React.useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  // NAVIGATION
  const handleRelocate = (location: string) => {
    navigate(location);
  };

  // AUTHENTICATION
  const getCsrf = async (): Promise<string | null> => {
    // Best to get x-csrf just before it is used, not worth saving saving in state
    try {
      const response = await fetch('/api/authentication/csrf/', {
        credentials: 'same-origin',
      });
      const csrfToken = response.headers.get('X-CSRFToken');
      return csrfToken;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  useEffect(() => {
    /* Sets an attribute on document that can be used in all css files to customise style for ipad like so 

     .primary {
       background-color: var(--dark-blue);
      }

     html[is-ipad="true"] .primary{
        background-color: red;
      }

    */
    if (isIpad) {
      document.documentElement.setAttribute('is-ipad', 'true');
    }
  }, []);

  const isIpad = useMemo(() => {
    return (
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
      navigator.platform === 'iPad'
    );
  }, []);

  const handleLogin = async (
    username: string,
    password: string
  ): Promise<Response> => {
    const currentCsrf = await getCsrf();
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('X-CSRFToken', currentCsrf || '');
    const response = await fetch('/api/authentication/login/', {
      method: 'POST',
      headers: requestHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    });
    return response;
  };

  const handleLogout = async (): Promise<void> => {
    const response = await fetch('/api/authentication/logout/', {
      credentials: 'same-origin',
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    handleRelocate('/');
  };

  const getUser = async (): Promise<User> => {
    if (user) {
      return user;
    }
    const response = await fetch('/api/authentication/current-user/', {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const requestedUser = await response.json();
    if (requestedUser) {
      setUser(requestedUser);
    }
    return requestedUser;
  };

  const isAuthenticated = async (): Promise<User | undefined> => {
    const response = await fetch('/api/authentication/session/', {
      credentials: 'same-origin',
    });
    if (!response?.ok) {
      return;
    }
    const requestedUser = await response.json();
    setUser(requestedUser);
    return requestedUser;
  };

  const value = {
    handleLogout,
    handleRelocate,
    getUser,
    handleLogin,
    isAuthenticated,
    isIpad,
    getCsrf,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
